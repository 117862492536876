import { Flex } from '@chakra-ui/react'
import { PageProps } from 'gatsby'

import { FC } from 'react'

import { withPage } from '~/components/page'
import Wizard from '~/components/wizard/Wizard'

interface Props extends PageProps {
	params: {
		personId: string
	}
}

const WizardPage: FC<Props> = props => {
	return (
		<Flex h='full' direction='column'>
			<Wizard personId={props.params.personId} />
		</Flex>
	)
}

export default withPage(WizardPage, {
	public: true,
	name: 'Wizard',
})
