import { AddIcon, DeleteIcon } from '@chakra-ui/icons'
import { Box, Button, Text } from '@chakra-ui/react'
import ImageUploading, { ImageListType } from 'react-images-uploading'
import { ExportInterface } from 'react-images-uploading/dist/typings'

import React, { useEffect } from 'react'

export interface PhotoFormProps {
	setPhotosStepDisabled: (value: boolean) => void
	setFieldValue: (key: string, value: any) => void
}

export const TeethPhotoForm: React.FC<PhotoFormProps> = ({
	setPhotosStepDisabled,
	setFieldValue,
}) => {
	const [images, setImages] = React.useState([])
	const maxNumber = 7

	const onChange = (imageList: ImageListType, _addUpdateIndex: number[] | undefined) => {
		// data for submit
		setImages(imageList as never[])
		setFieldValue(
			'teeth_photos',
			imageList.map(image => image.dataURL)
		)
	}

	useEffect(() => {
		setPhotosStepDisabled(images.length < 1)
	}, [images])

	const renderUploaderItem = (props: ExportInterface, index: number) => {
		return (
			<Box
				sx={{
					width: '185px',
					'@media (max-width: 1250px)': {
						width: 'calc(100% / 3 - 10px)',
					},
				}}
			>
				<Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
					<Box sx={{ width: '100%', height: '100px' }}>
						{props.imageList.length > index ? (
							<Box
								sx={{
									background: '#919191',
									position: 'relative',
									borderRadius: '8px',
									width: '100%',
									height: '100%',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									overflow: 'hidden',
									img: {
										maxWidth: '185px',
									},
								}}
							>
								<img src={props.imageList[index].dataURL} alt='' />
								<Button
									onClick={() => {
										const removingImageURL = props.imageList[index].dataURL
										const newImages = props.imageList.filter(
											image => image.dataURL !== removingImageURL
										)
										setImages(newImages as never[])
										props.onImageRemove(index)
									}}
									sx={{
										position: 'absolute',
										padding: 0,
										background: 'rgba(0,0,0,0.6)',
										'&:hover': { background: 'rgba(0,0,0,0.8)' },
									}}
								>
									<DeleteIcon color='#fff' />
								</Button>
							</Box>
						) : (
							<Button
								onClick={props.onImageUpload}
								{...props.dragProps}
								sx={{
									border: props.isDragging ? '2px dashed #D2196C' : '2px dashed #A5B0BA',
									width: '100%',
									height: '100%',
									backgroundColor: '#F5F7FB',
									color: props.isDragging ? '#D2196C' : '#A5B0BA',
									borderRadius: '8px',
									padding: '10px',
									display: 'flex',
									flexDirection: 'column',
									cursor: 'pointer',
								}}
							>
								<AddIcon />
								<Text
									fontSize='0.8em'
									color='#A5B0BA'
									textAlign='center'
									mt={3}
									whiteSpace='normal'
								>
									upload your photo
								</Text>
							</Button>
						)}
					</Box>
				</Box>
			</Box>
		)
	}

	return (
		<Box sx={{ width: '100%', display: 'flex' }}>
			<Box
				sx={{
					minWidth: '220px',
					gap: 5,
					display: 'flex',
					flexDirection: 'column',
					marginRight: '50px',
					'@media (max-width: 1250px)': {
						display: 'none',
					},
				}}
			>
				<Text fontSize='0.8em' color='#323E48' mt={3} padding='10px 15px'>
					Open your mouth wide and tilt your chin up and then down for a better view of all your
					teeth.
				</Text>
				<Box
					sx={{
						background: '#F5F7FB',
						borderRadius: '16px',
						padding: '10px 15px',
					}}
				>
					<Text
						fontSize='0.8em'
						color='#323E48'
						sx={{
							span: {
								color: '#D2196C',
							},
						}}
					>
						<span>Pro Tip: </span>
						In selfie mode, turn your phone upside down to take pictures of your upper teeth.
					</Text>
				</Box>
			</Box>
			<Box>
				<ImageUploading multiple value={images} onChange={onChange} maxNumber={maxNumber}>
					{props => (
						<Box sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									gap: 3,
									'@media (max-width: 1250px)': {
										flexWrap: 'wrap',
									},
								}}
							>
								{Array.from({ length: 6 }).map((_, index) => {
									return (
										<Box
											key={index}
											sx={{
												width: '100%',
												backgroundColor: '#F5F7FB',
												borderRadius: '8px',
												display: 'flex',
												flexDirection: 'column',
												img: {
													width: '100%',
												},
												'@media (max-width: 1250px)': {
													width: 'calc(100% / 3 - 10px)',
												},
											}}
										>
											<img src={`/wizard/teeth_example_${index + 1}.jpg`} alt='' />
										</Box>
									)
								})}
							</Box>
							<Box sx={{ display: 'flex', alignItems: 'center', gap: 3, flexWrap: 'wrap' }}>
								{Array.from({
									length: images.length === 7 ? images.length : images.length + 1,
								}).map((_, index) => {
									return (
										<React.Fragment key={index}>{renderUploaderItem(props, index)}</React.Fragment>
									)
								})}
							</Box>
						</Box>
					)}
				</ImageUploading>
			</Box>
		</Box>
	)
}

export default TeethPhotoForm
