import { gql, useMutation } from '@apollo/client'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import {
	Box,
	Button,
	Progress,
	Radio,
	RadioGroup,
	Spinner,
	Stack,
	Step,
	StepIcon,
	StepIndicator,
	Stepper,
	StepStatus,
	Text,
	Textarea,
} from '@chakra-ui/react'
import { useFormik } from 'formik'

import React, { useState } from 'react'

import { onError } from '~/components/helpers'
import useToast from '~/components/ui/Toast'
import {
	updatePersonWizardData as updatePersonWizardDataTypes,
	updatePersonWizardDataVariables as updatePersonWizardDataVariablesTypes,
} from '~/components/wizard/__generated__/updatePersonWizardData'
import { getWizardConfig } from '~/components/wizard/config'

import { personWizardData } from '../../../__generated__/globalTypes'

const leftBoxStyles = {
	width: '300px',
	img: {
		minWidth: '300px',
	},
	'@media (max-width: 1250px)': {
		width: '200px',
		img: {
			minWidth: '200px',
		},
	},
	'@media (max-width: 550px)': {
		display: 'none',
	},
}

const questionTitleStyles = {
	fontSize: '1.8em',
	color: '#323E48',
	marginBottom: '10px',
	textAlign: 'left',
	'@media (max-width: 1250px)': {
		fontSize: '1.5em',
	},
}

export const updatePersonWizardMutation = gql`
	mutation updatePersonWizardData($data: personWizardData!) {
		updatePersonWizardData(data: $data) {
			success
			message
		}
	}
`

interface WizardFormProps {
	personId: string
	savedWizardStep: any | null
}

const WizardForm = ({ personId, savedWizardStep }: WizardFormProps) => {
	const toast = useToast()
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const [updatePersonWizardHandler] = useMutation<
		updatePersonWizardDataTypes,
		updatePersonWizardDataVariablesTypes
	>(updatePersonWizardMutation, {
		onError: error => onError(error, toast),
		refetchQueries: ['PersonDetails'],
		onCompleted: () => setIsLoading(false),
	})
	const [step, setStep] = useState<number>(savedWizardStep || 0)
	const [photosStepDisabled, setPhotosStepDisabled] = useState<boolean>(true)

	const formik = useFormik({
		initialValues: {
			personId: personId,
			dream_outcome: '',
			treatment_reason: '',
			comes_from: '',
			face_photos: '',
			teeth_photos: [],
			wizard_step: 0,
		},
		validateOnChange: false,
		onSubmit: (values: personWizardData) => {
			submitHandler(values)
		},
	})

	const wizardConfig = getWizardConfig(setPhotosStepDisabled, formik.setFieldValue)

	const totalSteps = wizardConfig.length
	const max = totalSteps - 1
	const progressPercent = (step / max) * 100

	const handleNext = async () => {
		if (step <= max) {
			setIsLoading(true)
			await submitHandler(formik.values)
				.then(() => {
					setStep(step + 1)
					setPhotosStepDisabled(true)
				})
				.catch(err => {
					console.log(err)
					toast({
						title: 'Something went wrong. Please try again',
						status: 'error',
						position: 'top-right',
					})
				})
		}
	}

	const handlePrev = () => {
		if (step > 0) {
			setStep(step - 1)
			setPhotosStepDisabled(true)
		}
	}

	const submitHandler = async (formData: personWizardData) => {
		return await updatePersonWizardHandler({
			variables: { data: { ...formData, wizard_step: step + 1 } },
		})
	}

	const onSubmit = async () => {
		console.log(formik.values, 'submit')
	}

	const stepData = wizardConfig[step]

	const checkStepDisabled = () => {
		if (stepData?.name === 'face_photos' || stepData?.name === 'teeth_photos') {
			return photosStepDisabled || isLoading
		}
		return step === wizardConfig.length - 1 || !formik.values[stepData?.name] || isLoading
	}

	const renderOptionsBox = () => {
		if (!stepData?.component && stepData?.options?.length) {
			return (
				<Box sx={{ display: 'flex', marginTop: '40px', paddingBottom: '40px' }}>
					{stepData.image ? (
						<Box sx={leftBoxStyles}>
							<img src={stepData.image} />
						</Box>
					) : null}
					<Box
						sx={{
							marginLeft: '50px',
							'@media (max-width: 1250px)': {
								marginLeft: '20px',
							},
						}}
					>
						<Text sx={questionTitleStyles}>{stepData.question}</Text>
						<Box>
							<form
								onSubmit={e => {
									e.preventDefault()
									onSubmit()
								}}
							>
								{stepData.options ? (
									<Stack>
										<RadioGroup
											onChange={value => formik.setFieldValue(stepData.name, value)}
											value={formik.values[stepData.name]}
										>
											<Stack direction='column'>
												{stepData.options.map(option => {
													return (
														<Radio
															key={option.value}
															size='lg'
															value={option.value}
															name={wizardConfig[step].name}
															colorScheme='pink'
														>
															<Text fontSize='1.2em' color='#323E48'>
																{option.label}
															</Text>
														</Radio>
													)
												})}
											</Stack>
										</RadioGroup>
									</Stack>
								) : null}
							</form>
						</Box>
					</Box>
				</Box>
			)
		}
		return null
	}

	const renderComponentBox = () => {
		if (stepData?.component) {
			return (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						marginTop: '40px',
						paddingBottom: '40px',
					}}
				>
					<Text sx={questionTitleStyles}>{stepData?.question}</Text>
					{stepData?.component}
				</Box>
			)
		}
		return null
	}

	const renderTextBox = () => {
		if (!stepData.component && !stepData.options?.length) {
			return (
				<Box sx={{ display: 'flex', marginTop: '40px', paddingBottom: '40px' }}>
					{stepData.image ? (
						<Box sx={leftBoxStyles}>
							<img src={stepData.image} />
						</Box>
					) : null}
					<Box
						sx={{
							marginLeft: '50px',
							'@media (max-width: 1250px)': {
								marginLeft: '20px',
							},
							'@media (max-width: 550px)': {
								marginLeft: '0',
								width: '100%',
							},
						}}
					>
						<Text sx={questionTitleStyles}>{stepData.question}</Text>
						<form
							onSubmit={e => {
								e.preventDefault()
								onSubmit()
							}}
						>
							<Textarea
								width='400px'
								rows={3}
								value={formik.values[stepData.name]}
								placeholder='Type here...'
								sx={{
									'@media (max-width: 1250px)': {
										width: '100%',
									},
								}}
								color='#323E48'
								maxLength={255}
								resize={'none'}
								onChange={evt => formik.setFieldValue(stepData.name, evt.target.value)}
							/>
						</form>
					</Box>
				</Box>
			)
		}
		return null
	}

	const renderSteps = () => {
		return (
			<Box>
				<Stepper size='sm' index={step} gap='0' colorScheme='pink'>
					{wizardConfig.map((_step, index) => (
						<Box
							key={index}
							sx={{
								display: 'flex',
								alignItems: 'center',
								flexDirection: 'column',
								position: 'relative',
								left: index === 0 ? '-10px' : 'auto',
								right: index === wizardConfig.length - 1 ? '-10px' : 'auto',
							}}
						>
							<Step style={{ zIndex: 10 }}>
								<StepIndicator bg='white'>
									<StepStatus complete={<StepIcon />} />
								</StepIndicator>
							</Step>
							<Text fontSize='0.8em' color='#323E48'>
								Step {++index}
							</Text>
						</Box>
					))}
				</Stepper>
				<Progress
					colorScheme='pink'
					value={progressPercent}
					position='absolute'
					height='3px'
					width='full'
					top='10px'
				/>
			</Box>
		)
	}

	const stepDisabled = checkStepDisabled()

	if (!stepData || step === wizardConfig.length) {
		return null
	}

	return (
		<Box
			sx={{
				width: '100%',
				padding: '20px',
				borderRadius: '12px',
				background: '#fff',
			}}
		>
			<Box sx={{ display: 'flex', gap: 2 }}>
				<Text fontSize='1em' color='#323E48'>
					Ruh Dental
				</Text>
				<Text fontSize='1em' color='#323E48'>
					{'>'}
				</Text>
				<Text fontSize='1em' color='#D2196C'>
					Pre-Visit Consultation
				</Text>
			</Box>
			<Box
				sx={{
					padding: '0 40px',
					'@media (max-width: 790px)': {
						padding: '0',
					},
				}}
			>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						width: '100%',
						justifyContent: 'center',
						margin: '15px 0',
					}}
				>
					<Text
						fontSize='2em'
						color='#323E48'
						sx={{
							span: {
								color: '#D2196C',
							},
						}}
					>
						<span>Step {step + 1}</span> of {totalSteps}
					</Text>
				</Box>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						'@media (max-width: 550px)': {
							justifyContent: 'space-between',
						},
					}}
				>
					<Button onClick={handlePrev} isDisabled={step === 0}>
						<ChevronLeftIcon fontSize={20} />
						Back
					</Button>
					<Box
						sx={{
							width: '100%',
							margin: '0 40px',
							position: 'relative',
							'@media (max-width: 550px)': {
								display: 'none',
							},
						}}
					>
						{renderSteps()}
					</Box>
					<Button
						onClick={handleNext}
						isDisabled={stepDisabled}
						colorScheme='pink'
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							width: '100px',
						}}
					>
						{isLoading ? (
							<Spinner />
						) : (
							<>
								Next
								<ChevronRightIcon fontSize={20} />
							</>
						)}
					</Button>
				</Box>
				{renderComponentBox()}
				{renderOptionsBox()}
				{renderTextBox()}
			</Box>
		</Box>
	)
}

export default WizardForm
